<template>
  <ui-wrap-modal
    v-bind="$attrs"
    size="lg"
  >
    <common-forms-create-or-update-media
      class="grid grid-cols-1 sm:gap-5 gap-2.5"
      :file="file"
      :is-public="true"
      :create="create"
      @success="onSuccess"
      @set-avatar="$emit('setAvatar', file)"
    >
      <ui-file-preview
        v-if="!create"
        class="rounded-lg overflow-hidden w-full"
        :file="file"
      />
    </common-forms-create-or-update-media>
  </ui-wrap-modal>
</template>

<script setup lang="ts">
  type Props = {
    file: File;
    create?: boolean;
  };
  defineProps<Props>();

  const emit = defineEmits(['update:modelValue', 'setAvatar']);

  const { onSuccess } = createModalState(emit);
</script>
