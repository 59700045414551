<template>
  <form @submit="onSubmit">
    <div class="flex flex-wrap gap-2.5 justify-center">
      <ui-fields-media-status-control
        :checked-value="1"
        name="is_public"
      />
      <ui-fields-media-status-control
        :checked-value="0"
        name="is_public"
      />
      <ui-button
        size="sm"
        class="w-full sm:w-auto"
        variant="primary"
        icon-left="person"
        text="Set as Thumbnail"
        @click="emit('setAvatar')"
      />
    </div>
    <slot />

    <ui-fields-file-image-cropper
      v-if="create"
      :file="file"
      class="w-full mx-auto"
      :loading="isSubmitting"
      @crop="onCropFile"
    />
    <ui-button
      v-else
      :text="attachId ? 'Update' : 'Save'"
      type="submit"
      :loading="isSubmitting"
    />
  </form>
</template>

<script setup lang="ts">
  import { useForm } from 'vee-validate';

  type Props = {
    isPublic?: boolean;
    attachId?: number;
    file: File | string;
    create?: boolean;
  };

  const props = defineProps<Props>();
  const emit = defineEmits(['success', 'setAvatar']);

  const apiRoutes = useApiRoutes();
  const limitToasts = useLimitToasts();
  const initialValues = computed(() => {
    return {
      is_public: +props.isPublic,
      file: props.file || undefined
    };
  });

  const { handleSubmit, isSubmitting, setValues, setFieldValue } = useForm({
    initialValues
  });

  watch(
    () => props.attachId,
    () => {
      setValues({ is_public: +props.isPublic });
    }
  );

  const onSubmit = handleSubmit(
    useSubmitHandler(
      form => {
        return props.attachId ? apiRoutes.attachments.update(props.attachId, form) : apiRoutes.attachments.create(form);
      },
      initialValues,
      {
        isShowToastError: false,
        async onSuccess(data: Attachment) {
          emit('success', data);
        },
        successToast: true,
        onError: limitToasts
      }
    )
  );

  const onCropFile = ({ file }: { file: Blob }) => {
    setFieldValue('file', file);
    onSubmit();
  };
</script>
