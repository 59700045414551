<template>
  <ui-fields-control
    v-bind="props"
    type="radio"
    :checked-value="checkedValue"
    hide-error
  >
    <template #control="{ checked, inputId }">
      <common-profile-media-status
        tag="label"
        :for="inputId"
        :is-public="!!checkedValue"
        :is-active="checked"
        class="cursor-pointer"
      />
    </template>
  </ui-fields-control>
</template>

<script setup lang="ts">
  type Props = { checkedValue: number };
  const props = defineProps<Props>();
</script>
